<template>

  <div class="dashboard">

    <div class="row">
      <div class="col-12 section-head my-3">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="section-head-title p-3 ps-0 mb-0">
              <i class="fa-solid fa-circle-plus color-secondary"></i>
              Conoce las cifras más importantes de <strong>Beneficios</strong>
            </h3>
          </div>
          <div class="col-12 col-lg-6">
            <div class="section-head-filter d-flex justify-content-end">
            <div class="d-flex align-items-center pe-2 text-secondary">
              <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar
            </div>
            <div 
              class="section-head-filter-input d-flex" 
              v-click-outside="handleCalendar">
              <div 
                class="calendar" 
                :class="calendar_side" 
                v-if="show_calendar"
              >
                <v-calendar 
                  color="blue" 
                  :max-date="max_date" 
                  :min-date="min_date" 
                  :attributes="attributes"
                  v-model="date_init" 
                  @dayclick="onDayClick" 
                />
                <button 
                  class="btn btn-custom-color-white reset-date"
                  @click="fecha_fin = 'Término',fecha_inicio = 'Inicio',getDash(),show_calendar=false"
                > Reiniciar
                </button>
                <div 
                  class="border-arrow" 
                  :class="calendar_side">
                  <div class="arrow"></div>
                </div>
              </div>
              <div 
                class="section-head-filter-input-start" 
                @click="openCalendar('start-date')">
                <span>{{ dateFormatymd(fecha_inicio) }}</span>
                <div class="icon">
                  <img :src="img_calendar"/>
                </div>
              </div>
              <div 
                class="section-head-filter-input-end disabled" 
                v-if="fecha_inicio == 'Inicio'">
                <span>{{ dateFormatymd(fecha_fin) }}</span>
                <div class="icon">
                  <img :src="img_calendar"/>
                </div>
              </div>
              <div 
                class="section-head-filter-input-end"
                v-if="fecha_inicio != 'Inicio'" 
                @click="openCalendar('end-date')">
                <span>{{ dateFormatymd(fecha_fin) }}</span>
                <div class="icon">
                  <img :src="img_calendar"/>
                </div>
              </div>
            </div>          
          </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">

            <!--CARD NUMBERS-->
            <div class="card card-numbers border-round-10 card-shadow mb-3">
              <div class="card-body">
                <div class="card-numbers-head d-flex align-items-center color-main">
                  <img class="card-numbers-head-icon" src="@/assets/img/benefit.svg"/>
                  <div class="card-numbers-head-num">{{ dash_data.total_beneficiados }}</div>
                  <div class="card-numbers-head-text">
                    Usuarios <br>
                    beneficiados
                  </div>
                </div>
                <div class="card-numbers-text mt-3">
                  {{ intro_text }}
                </div>
              </div>
            </div>

            <!--CARD PIE-->
            <div 
              class="card card-pie border-round-10 card-shadow mb-3 mb-lg-0" 
              v-show="showChart()">
              <div class="card-body">
                  <div class="row">
                    <div class="col-7">
                      <div class="card-pie-graph">
                        <apexchart
                          :key="key_chart"
                          :options="chartOptions" 
                          :series="series">
                        </apexchart>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="card-pie-legend d-flex flex-column justify-content-center align-items-start ps-3">
                        <h5 class="mb-3">Estadísticas por estado</h5>
                        <div 
                          class="card-pie-legend-text d-flex align-items-center" 
                          v-for="(rela, i) in dash_data.solicitudes_tipo" 
                          :key="i">
                          <div 
                            class="card-pie-legend-dot" 
                            :style="`background-color: ${chartOptions.colors[i]};`">
                          </div>
                          {{ rela.nombre_solicitud }}
                        </div>
                      </div>                  
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">

              <!--CARD BARS-->
              <div 
                class="col-12 col-xl-6 mb-3" 
                v-for="(data, i) in dash_data.solicitudes_estado" :key="i">
                <div class="card card-shadow card-bars border-round-10">
                  <div class="card-body">
                    <div class="card-bars-title">{{ data.nombre }}</div>
                    <div class="card-bars-count d-flex align-items-center my-2">
                      <div class="card-bars-count-num pe-2">{{ data.cantidad_solicitudes }}</div>
                      <div class="card-bars-count-val">
                        Solicitudes
                      </div>
                    </div>
                    <div class="card-bars-bar">
                      <div 
                        class="card-bars-bar-back bg-blue-gray">
                      </div>
                      <div 
                        class="card-bars-bar-front bg-blue"
                        :style="`width:${data.porcentaje_solicitudes}%;`">
                      </div>
                    </div>
                  </div>
                </div>      
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { DASHBOARD_BENEFICIO, GET_VALORES } from "@/apollo/queries";
export default {
  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        subtitle: "Dashboard",
      },
      series: [0, 0, 0],
      chartOptions: {
        chart: {
          type: "donut",
          fontFamily: "Product Sans Bold",
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 1,
            color: "#0072f7",
            opacity: 0.35,
          },
        },
        dataLabels: {
          style: {
            colors: ["#fff"],
            fontSize: "14px",
            fontWeight: "bold",
          },
          /*  formatter: function (val) {
            return Math.round(val)
          }, */
          dropShadow: {
            top: 0,
            left: 0,
            blur: 1,
            color: "#0072f7",
            opacity: 0.45,
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          show: false,
          curve: "smooth",
          lineCap: "butt",
          colors: ["#fff"],
          width: 2,
          dashArray: 0,
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
            },
            donut: {
              size: "50%",
              background: "transparent",
            },
          },
        },
        colors: ["#D73B6C", "#F6C700", "#23BE61"],
      },
      dash_data: {},
      show_calendar: false,
      calendar_side: "",
      fecha_inicio: "Inicio",
      fecha_fin: "Término",
      max_date: "",
      min_date: "",
      key_dash: 1,
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      valores: [],
      value_colors: [],
      key_chart: 0,

      //FF
      intro_text: "Beneficios te permite acercar y agilizar el proceso de solicitudes. Te recomendamos revisar constantemente las estadísticas para llevar un registro de ellas y conocer sus estados."
    };
  },
  mounted() {
    this.getDash();
  },
  computed: {
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  methods: {
    showChart() {
      //console.log("Show chart", this.series);
      var suma = 0;
      for (let i = 0; i < this.series.length; i++) {
        const element = this.series[i];
        suma = suma + Number(element);
      }
      if (suma == 0) {
        return false;
      }
      if (suma != 0) {
        return true;
      }
    },
    getValores() {
      this.value_colors = {};
      this.$apollo
        .query({
          query: GET_VALORES,
          variables: {
            id_empresa: this.id_empresa,
          },
        })
        .then((res) => {
          var resp = res.data.valoresReconocimientos;
          this.valores = resp;

          resp.forEach((el) => {
            this.value_colors[el.nombre_valor] = el.color;
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getDash() {
      var date_true =
        this.fecha_inicio == "Inicio" || this.fecha_fin == "Término";
      this.$apollo
        .query({
          query: DASHBOARD_BENEFICIO,
          variables: {
            id_empresa: this.id_empresa,
            historico: date_true ? "0" : "1",
            fecha_inicio: date_true ? "" : this.fecha_inicio,
            fecha_final: date_true ? "" : this.fecha_fin,
          },
        })
        .then((res) => {
          var resp = res.data.DashboardSolicitud;
          //console.log("total_beneficiados", resp.total_beneficiados);
          //console.log("total_solicitudes", resp.total_solicitudes);
          //console.log("solicitudes_estado", resp.solicitudes_estado);
          //console.log("solicitudes_tipo", resp.solicitudes_tipo);
          this.dash_data = resp;
          var v_series = [];
          var v_chartOptions = [];
          for (let i = 0; i < resp.solicitudes_tipo.length; i++) {
            var el = resp.solicitudes_tipo[i];
            v_chartOptions.push(`${el.nombre_solicitud}`);
            v_series.push(
              Math.round(Number(el.cantidad_solicitudes.split(".")[0]))
            );
          }
          //console.log("v_series", v_series);
          this.series = v_series;
          this.chartOptions.labels = v_chartOptions;
          this.key_chart = this.key_chart + 1;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        this.date_init =
          this.fecha_inicio != "Inicio" ? new Date(this.fecha_inicio) : "";
        this.max_date = this.fecha_fin == "Término" ? "" : this.fecha_fin;
        this.min_date = "";
      } else {
        var min = new Date(this.fecha_inicio);
        this.min_date = min.setDate(min.getDate() + 1);
        this.max_date = "";
        this.min_date = this.fecha_inicio == "Inicio" ? "" : this.fecha_inicio;
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.fecha_inicio = day.id;
      } else {
        this.fecha_fin = day.id;
      }
      if (this.fecha_inicio != "Inicio" && this.fecha_fin != "Término") {
        this.getDash();
      }
      this.show_calendar = false;
    },
    handleCalendar() {
      // console.log("click outside calendar");
      this.show_calendar = false;
    },
  },
};
</script>
